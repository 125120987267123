(function () {
  'use strict';

  /* @ngdoc object
   * @name events.reports
   * @description
   *
   */
  angular
    .module('events.reports', [
      'ui.router'
    ]);
}());
